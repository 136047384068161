$(document).ready(function () {
    var swiper = new Swiper('.list-service', {
        slidesPerView: 4,
        spaceBetween: 10,
        direction: getDirection(),
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            resize: function () {
                swiper.changeDirection(getDirection());
            }
        },

    });


    function getDirection() {
        var windowWidth = window.innerWidth;
        var direction = window.innerWidth <= 768 ? 'vertical' : 'horizontal';

        return direction;
    }

    var list = new Swiper('.list-testimonial', {
        slidesPerView: 3,
        spaceBetween: 35,
        direction: getDirection(),
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            resize: function () {
                list.changeDirection(getDirection());
            }
        }
    });
    var list = new Swiper('.list-notary', {
        slidesPerView: 3,
        spaceBetween: 30,
        direction: getDirection(),
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            resize: function () {
                list.changeDirection(getDirection());
            }
        }
    });
    var list = new Swiper('.list-blog', {
        slidesPerView: 3,
        spaceBetween: 35,
        direction: getDirection(),
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            resize: function () {
                list.changeDirection(getDirection());
            }
        }
    });
});